<script setup lang="ts">
import { Btn } from 'webcc-ui-components'
import { OtherFormType } from '~/schema'

definePageMeta({
  layout: 'loggedin',
  title: 'components.common.guestsReviews',
})

const { t } = useI18n()
const { $ROUTE } = useNuxtApp()

const { data: accommodations } = await useTryCatchSuspense(
  useAccommodationsQuery,
)

const { accommodationId } = useAccommodationIdProvider()

const { suspense: reviewTargetSuspense } = useReviewTargetList()
const {
  data,
  isPending,
  isFetchingNextPage,
  isError,
  fetchNextPage,
  suspense: reviewsSuspense,
} = useReviewsQuery(accommodationId)

try {
  await Promise.all([reviewTargetSuspense(), reviewsSuspense()])
} catch (e) {}

const reviewsCount = computed(() => data.value?.reviews?.length ?? 0)

const breadcrumbs = computed(() => [
  {
    url: $ROUTE.INDEX,
    label: t('components.common.home'),
  },
  {
    label: t('components.common.guestsReviews'),
  },
])

const noReviewText = computed(() =>
  serializeSentenceWithLink(t('components.common.emptyDataFallback')),
)
</script>

<template>
  <main
    class="flex-full-h gap-y-4 md:gap-y-6 mb-4 xl:gap-y-10 md:mb-20"
    data-id="reviews-page"
  >
    <BasicBreadcrumbs :items="breadcrumbs" />

    <BasicPageContent>
      <OnDevices rule=">=lg">
        <BasicPageHeading
          class="mb-4 md:mb-8 xl:mb-4"
          :title="$t('components.common.guestsReviews')"
        />
      </OnDevices>

      <LazyReviewsFilterButtons
        v-if="Number(accommodations?.length) > 1"
        :accommodations="accommodations!"
      />

      <BasicContentWrapper :is-error="isError" :is-loading="isPending">
        <ReviewsContainer
          v-if="reviewsCount > 0"
          :reviews="data!.reviews"
          :pagination="data!.pagination"
          :average-rating="data!.averageRating"
          :loading="isFetchingNextPage"
          @load-more="fetchNextPage"
        />
        <BasicDataFallback v-else :title="$t('components.reviews.0Reviews')">
          {{ noReviewText.labelBefore }}

          <Btn
            variant="link"
            class="font-normal"
            :label="noReviewText.linkText"
            @click="
              navigateTo(`${$ROUTE.CONTACT_US}?type=${OtherFormType.Other}`)
            "
          />
        </BasicDataFallback>
      </BasicContentWrapper>
    </BasicPageContent>
  </main>
</template>
