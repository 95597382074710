<script setup lang="ts">
import { isEmpty } from 'lodash-es'
import { DATE_FORMAT_DDMMYYYY } from '~/constants'
import type { Review } from '~/composables/reviews'
import { ReviewTranslationState } from '~/composables/reviews'
import { ReviewDetailsRatings } from '~/schema'

const props = withDefaults(
  defineProps<{
    review: Review
    noDate?: boolean
    noHearts?: boolean
  }>(),
  {
    noDate: false,
    noHearts: false,
  },
)

const ReviewRating: Record<ReviewDetailsRatings, number> = {
  [ReviewDetailsRatings.NA]: 0,
  [ReviewDetailsRatings.Terrible]: 1,
  [ReviewDetailsRatings.Poor]: 2,
  [ReviewDetailsRatings.Average]: 3,
  [ReviewDetailsRatings.Good]: 4,
  [ReviewDetailsRatings.Great]: 5,
}

const { data: targetLanguages } = useReviewTargetList()

const language = ref()

const { t, locale } = useI18n()

const [isOriginalReview, toggleOriginalReview] = useToggle(true)
const { accommodationId } = useAccommodationIdContext()

const { data, isError, isFetching, isSuccess, refetch } = useReviewTranslation({
  review: computed(() => props.review),
  language,
  accommodationId,
})

watch(locale, () => toggleOriginalReview(true))

const hasDifferentLanguages = computed(
  () => props.review.language !== locale.value.toUpperCase(),
)

const isReviewTranslationVisible = computed(() => {
  return (
    hasDifferentLanguages.value &&
    targetLanguages.value?.includes(props.review.language) &&
    targetLanguages.value?.includes(locale.value.toUpperCase())
  )
})

const translatedReviewData = computed(() =>
  isFetching.value || isError.value || isOriginalReview.value
    ? { comment: props.review.comment, title: props.review.title }
    : { comment: data.value?.comment, title: data.value?.title },
)

const translationState = computed(() => {
  if (isError.value) return ReviewTranslationState.Error
  if (isFetching.value) return ReviewTranslationState.Progress
  if (
    isSuccess.value &&
    !isOriginalReview.value &&
    hasDifferentLanguages.value
  ) {
    return ReviewTranslationState.Success
  }
  return ReviewTranslationState.None
})

const hasRatings = computed(() => !isEmpty(props.review.ratings))

const ratingsBars = computed(() => {
  if (!hasRatings.value) return []
  return [
    'ConditionFurniture',
    'CleanlinessOverall',
    'OverallConditionFurniture',
    'OtherFacilites',
    'BeddingMattresses',
    'OnSiteCustomerService',
    'KitchenUtensils',
    'OverallCustomerService',
    'BathroomCleanliness',
  ].map((key) => {
    return {
      title: t(`components.reviews.${key}`),
      rating: ReviewRating[props.review.ratings[key]!],
    }
  })
})

function handleTranslationClick(
  type: Omit<ReviewTranslationState, ReviewTranslationState.Progress>,
) {
  switch (type) {
    case ReviewTranslationState.None: {
      language.value = locale.value
      toggleOriginalReview()
      break
    }
    case ReviewTranslationState.Success: {
      toggleOriginalReview()
      break
    }
    case ReviewTranslationState.Error: {
      refetch()
      break
    }
  }
}
</script>

<template>
  <div class="flex flex-col gap-y-2">
    <div v-if="!noDate && !noHearts" class="flex justify-between">
      <h3 v-if="!noDate" class="text-lg font-medium">
        {{ $date.formatDate(review.created, DATE_FORMAT_DDMMYYYY) }}
      </h3>

      <BasicRatingHearts v-if="!noHearts" :points="review.rate" />
    </div>

    <div class="flex flex-col items-end">
      <p class="mb-1 text-base font-medium">
        {{ review.houseName }}
      </p>

      <p class="text-base font-normal text-txt-400">
        {{ review.accommodationCode }}
      </p>
    </div>

    <div class="flex flex-col gap-y-1">
      <h4 class="text-base font-medium leading-6">
        {{ translatedReviewData.title }}
      </h4>

      <div class="text-base font-normal leading-6">
        {{ translatedReviewData.comment }}
      </div>

      <ReviewsTranslate
        v-if="isReviewTranslationVisible"
        :state="translationState"
        @click="handleTranslationClick"
      />
    </div>

    <div
      v-if="hasRatings"
      class="flex flex-col flex-wrap justify-between lg:flex-row"
      data-id="rating-bar"
    >
      <div
        v-for="bar in ratingsBars"
        :key="bar.title"
        :data-id="bar.title.split(' ').join('-').toLowerCase()"
        class="mb-4 w-full even:order-1 lg:w-[calc(50%_-_0.5rem)]"
      >
        <div class="flex flex-col gap-y-1">
          <div class="flex justify-between">
            <p>{{ bar.title }}</p>

            <span>
              {{ bar.rating }}/{{ ReviewRating[ReviewDetailsRatings.Great] }}
            </span>
          </div>
          <div class="w-full bg-bgr-200 h-4 lg:h-5">
            <div
              class="h-full bg-thm-active"
              :style="`width: ${(100 * bar.rating) / ReviewRating[ReviewDetailsRatings.Great]}%`"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
