<script setup lang="ts">
import { Btn } from 'webcc-ui-components'
import { ReviewTranslationState } from '~/composables/reviews'

defineProps<{
  state: ReviewTranslationState
}>()

defineEmits<{
  (
    e: 'click',
    type: Omit<ReviewTranslationState, ReviewTranslationState.Progress>,
  ): void
}>()

const [DefineReviewTranslationIndicator, ReviewTranslationIndicator] =
  createReusableTemplate<{
    title: string
    label: string
  }>()
</script>

<template>
  <DefineReviewTranslationIndicator v-slot="{ title, label }">
    <div class="flex gap-x-2">
      <span
        :class="{
          'review-translate-error': true,
          'text-err': ReviewTranslationState.Error === state,
        }"
      >
        {{ title }}
      </span>

      <Btn
        variant="link"
        class="review-translate"
        :label="label"
        @click="$emit('click', state)"
      />
    </div>
  </DefineReviewTranslationIndicator>

  <div data-id="review-translate">
    <Btn
      v-if="ReviewTranslationState.None === state"
      variant="link"
      class="review-translate"
      :label="$t('components.reviews.translateReview')"
      @click="$emit('click', state)"
    />

    <ReviewTranslationIndicator
      v-else-if="ReviewTranslationState.Success === state"
      :title="$t('components.reviews.translated')"
      :label="$t('components.reviews.showOriginal')"
    />

    <ReviewTranslationIndicator
      v-else-if="ReviewTranslationState.Error === state"
      :title="$t('components.reviews.translationError')"
      :label="$t('components.common.tryAgain').toLowerCase()"
    />

    <p v-else class="review-translate-fetching text-txt-400">
      {{ $t('components.reviews.translationInProgress') }}
    </p>
  </div>
</template>

<style scoped>
:deep(.review-translate) {
  color: theme('colors.thm.hover');
  font-weight: 400;
}

.review-translate-fetching:after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: '';
}

.review-translate-error:after {
  content: '-';
  padding-left: 8px;
  color: theme('colors.txt.DEFAULT');
}

@keyframes dotty {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}
</style>
