<script setup lang="ts">
import type { Review, ReviewsPagination } from '~/composables/reviews'

withDefaults(
  defineProps<{
    reviews: Review[]
    pagination: ReviewsPagination
    averageRating: number
    loading?: boolean
  }>(),
  {
    loading: false,
  },
)

defineEmits<{
  (e: 'load-more'): void
}>()

const selectedReview = ref<Review>()
</script>

<template>
  <ReviewsTotalRatingsBadge
    class="mb-6"
    :average-rating="averageRating"
    :total="pagination.total"
  />

  <div data-id="latest-review">
    <h2 class="mb-3 text-xl font-medium leading-6">
      {{ $t('components.reviews.latestReviews') }}
    </h2>

    <ReviewsExpandedCard class="mb-4" :review="reviews[0]" />
  </div>

  <ReviewsCards :reviews="reviews" @click="selectedReview = $event" />

  <BasicPagination
    class="mt-4"
    :total="pagination.total"
    :rest="pagination.rest"
    :disabled="loading"
    @load-more="$emit('load-more')"
  />

  <LazyReviewsModal
    v-if="selectedReview"
    :review="selectedReview"
    @close="selectedReview = undefined"
  />
</template>
